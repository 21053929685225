import {
	FILTERED_BRANCHLOCATIONS,
	FILTERED_CASHREGISTERS,
	FILTERED_INVOICES,
	FILTERED_TRANSACTIONS,
	FILTERED_VGAS,
	INCLUDE_CASHREGISTER_BY_AMOUNT,
	INCLUDE_CASHREGISTER_BY_CASHREGISTERCUT_DATE,
	INCLUDE_CASHREGISTER_BY_TERMINAL_ID,
	INCLUDE_TRANSACTION_BY_AMOUNT,
	INCLUDE_TRANSACTION_BY_CARD_TYPE,
	INCLUDE_TRANSACTION_BY_CASHREGISTERCUT_DATE,
	INCLUDE_TRANSACTION_BY_CURRENCY,
	INCLUDE_TRANSACTION_BY_LOCATION,
	INCLUDE_TRANSACTION_BY_STATE,
	INCLUDE_TRANSACTION_BY_TERMINAL_ID,
	INCLUDE_TRANSACTION_BY_TIME,
	INCLUDE_TRANSACTION_BY_TRANSACTION_ID,
	INCLUDE_TRANSACTION_BY_TYPE,
	SEARCH_PARAMS,
	SELECTED_CUSTOMER,
	SUM_AMOUNT_CASHREGISTERS,
	SUM_AMOUNT_INVOICES,
	SUM_AMOUNT_TRANSACTIONS,
	UNIQUE_BRANCHLOCATIONS,
	UNIQUE_CARD_TYPES,
	UNIQUE_CUSTOMERS
} from "@/store/constants";
import {GlobalState} from "@/store/types";
import {Branchlocation, Cashregister, Invoice, TransactionSIX} from "@/api/types";

interface IncludeTransactionGetters {
	INCLUDE_TRANSACTION_BY_TRANSACTION_ID: (transaction: TransactionSIX) => boolean;
	INCLUDE_TRANSACTION_BY_TERMINAL_ID: (transaction: TransactionSIX) => boolean;
	INCLUDE_TRANSACTION_BY_LOCATION: (transaction: TransactionSIX) => boolean;
	INCLUDE_TRANSACTION_BY_CURRENCY: (transaction: TransactionSIX) => boolean;
	INCLUDE_TRANSACTION_BY_AMOUNT: (transaction: TransactionSIX) => boolean;
	INCLUDE_TRANSACTION_BY_TIME: (transaction: TransactionSIX) => boolean;
	INCLUDE_TRANSACTION_BY_STATE: (transaction: TransactionSIX) => boolean;
	INCLUDE_TRANSACTION_BY_CARD_TYPE: (transaction: TransactionSIX) => boolean;
	INCLUDE_TRANSACTION_BY_TYPE: (transaction: TransactionSIX) => boolean;
	INCLUDE_TRANSACTION_BY_CASHREGISTERCUT_DATE: (transaction: TransactionSIX) => boolean;
}

interface IncludeCashregisterGetters {
	INCLUDE_CASHREGISTER_BY_TERMINAL_ID: (transaction: Cashregister) => boolean;
	INCLUDE_CASHREGISTER_BY_AMOUNT: (transaction: Cashregister) => boolean;
	INCLUDE_CASHREGISTER_BY_CASHREGISTERCUT_DATE: (transaction: Cashregister) => boolean;
}


const getters = {
	[INCLUDE_TRANSACTION_BY_TRANSACTION_ID]: (state: GlobalState) => (transaction: TransactionSIX) => {
		if (!state.transactionIdFilter) {
			return true
		}
		if(!transaction.code){
			return false;
		}
		return transaction.code.includes(state.transactionIdFilter);
	},
	[INCLUDE_TRANSACTION_BY_TERMINAL_ID]: (state: GlobalState) => (transaction: TransactionSIX) => {
		if (!state.transactionTerminalIdFilter) {
			return true;
		}
		return transaction.terminal_number.includes(state.transactionTerminalIdFilter);
	},
	[INCLUDE_TRANSACTION_BY_LOCATION]: (state: GlobalState) => (transaction: TransactionSIX) => {
		if (state.selectedBranchlocation && state.selectedBranchlocation.id) {
			return state.selectedBranchlocation.terminal_numbers.includes(transaction.terminal_number)
		}
		return true;
	},
	[INCLUDE_TRANSACTION_BY_CURRENCY]: (state: GlobalState) => (transaction: TransactionSIX) => {
		return true;
	},
	[INCLUDE_TRANSACTION_BY_TYPE]: (state: GlobalState) => (transaction: TransactionSIX) => {
		return true;
	},
	[INCLUDE_TRANSACTION_BY_AMOUNT]: (state: GlobalState) => (transaction: TransactionSIX) => {
		if (!state.transactionAmountFromFilter && !state.transactionAmountToFilter) {
			return true;
		}
		if (!state.transactionAmountToFilter) {
			return transaction.amount >= state.transactionAmountFromFilter;
		}
		if (!state.transactionAmountFromFilter) {
			return transaction.amount <= state.transactionAmountToFilter;
		}
		return transaction.amount >= state.transactionAmountFromFilter && transaction.amount <= state.transactionAmountToFilter;
	},
	[INCLUDE_TRANSACTION_BY_TIME]: (state: GlobalState) => (transaction: TransactionSIX) => {
		if (!state.transactionTimeToFilter || !state.transactionTimeFromFilter) {
			return true;
		}
		const filedate = transaction.date;
		const filedatetime = filedate + " " + transaction.time;
		const datetimefrom = state.dateRange[0] + " " + state.transactionTimeFromFilter + ":00";
		const datetimeto = state.dateRange[1] + " " + state.transactionTimeToFilter + ":00";

		 // console.log("##########");
		 // console.log("File: " + filedatetime);
		 // console.log("From: " + datetimefrom);
		 // console.log(" To : " + datetimeto);
		 // console.log("higher: " + (filedatetime >= datetimefrom));
		 // console.log("lower : " + (filedatetime <= datetimeto));
		 // console.log("result: " + (filedatetime >= datetimefrom && filedatetime <= datetimeto));
		 // console.log("##########");

		return filedatetime >= datetimefrom && filedatetime <= datetimeto;
	},
	[INCLUDE_TRANSACTION_BY_CASHREGISTERCUT_DATE]: (state: GlobalState) => (transaction: TransactionSIX) => {
		if (!state.cashregistercutDateRange || !state.cashregistercutDateRange[0] || !state.cashregistercutDateRange[1]) {
			return true;
		}
		const filedate = transaction.filedate.slice(0, 10);
		return filedate >= state.cashregistercutDateRange[0] && filedate <= state.cashregistercutDateRange[1];
	},
	[INCLUDE_TRANSACTION_BY_STATE]: (state: GlobalState) => (transaction: TransactionSIX) => {

		if(transaction.type === "Gutschrift"){
			transaction.amount = transaction.amount * -1;
		}

		return transaction;
	},
	[INCLUDE_TRANSACTION_BY_CARD_TYPE]: (state: GlobalState) => (transaction: TransactionSIX) => {
		if (!state.transactionCardTypeFilter || !state.transactionCardTypeFilter.length) {
			return true;
		}
		let cardRoutings = state.transactionCardTypeFilter.filter((cardType) => cardType !== "Gutschrift");
		let isCardRoutingActive = cardRoutings.length > 0;
		let isGutschriftActive = state.transactionCardTypeFilter.includes("Gutschrift");

		if (isGutschriftActive && !isCardRoutingActive) {
			return transaction.type?.includes("Gutschrift") ?? false;
		}

		return cardRoutings.some((cardType) =>
			transaction.routing?.includes(cardType) && (!isGutschriftActive || transaction.type?.includes("Gutschrift"))
		);
	},
	[SUM_AMOUNT_TRANSACTIONS](state: GlobalState, getters: IncludeTransactionGetters) {
		let transactions = state.transactions;

		if (state.transactionIdFilter) {
			transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_TRANSACTION_ID(transaction));
		}
		if (state.transactionTerminalIdFilter) {
			transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_TERMINAL_ID(transaction));
		}
		if (state.selectedBranchlocation) {
			transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_LOCATION(transaction));
		}
		if (state.transactionAmountFromFilter || state.transactionAmountToFilter) {
			transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_AMOUNT(transaction));
		}
		if (state.transactionTimeFromFilter || state.transactionTimeToFilter) {
			transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_TIME(transaction));
		}
		if (state.transactionCardTypeFilter && state.transactionCardTypeFilter.length) {
			transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_CARD_TYPE(transaction));
		}
		if (state.cashregistercutDateRange && state.cashregistercutDateRange) {
			transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_CASHREGISTERCUT_DATE(transaction));
		}

		const routingsCounts = {
			"ALIPAY": 0,
			"American Express": 0,
			"CUP_CD": 0,
			"DINERS": 0,
			"ecEMV": 0,
			"JCB_CD": 0,
			"maestro": 0,
			"Mastercard": 0,
			"SepaELV": 0,
			"VISA": 0,
			"VPAY": 0,
			"Gutschrift" : 0,
			"notdefined": 0,
		};
		let transactionsSum = 0;
		transactions.forEach((transaction) => {
			const transactionAmount = parseFloat(transaction.amount.toString());
			switch (transaction.routing) {
				case "ALIPAY":
					routingsCounts["ALIPAY"] += transactionAmount;
					break;
				case "American Express":
					routingsCounts["American Express"] += transactionAmount;
					break;
				case "CUP_CD":
					routingsCounts["CUP_CD"] += transactionAmount;
					break;
				case "DINERS":
					routingsCounts["DINERS"] += transactionAmount;
					break;
				case "ecEMV":
					routingsCounts["ecEMV"] += transactionAmount;
					break;
				case "JCB_CD":
					routingsCounts["JCB_CD"] += transactionAmount;
					break;
				case "maestro":
					routingsCounts["maestro"] += transactionAmount;
					break;
				case "Mastercard":
					routingsCounts["Mastercard"] += transactionAmount;
					break;
				case "SepaELV":
					routingsCounts["SepaELV"] += transactionAmount;
					break;
				case "VISA":
				case "VISA electron":
					routingsCounts["VISA"] += transactionAmount;
					break;
				case "VPAY":
					routingsCounts["VPAY"] += transactionAmount;
					break;
				default:
					routingsCounts["notdefined"] += transactionAmount;
			}
			transactionsSum += transactionAmount;

		});

		return {
			sum: transactionsSum.toFixed(2),
			"ALIPAY": routingsCounts["ALIPAY"].toFixed(2),
			"American Express": routingsCounts["American Express"].toFixed(2),
			"CUP_CD": routingsCounts["CUP_CD"].toFixed(2),
			"DINERS": routingsCounts["DINERS"].toFixed(2),
			"ecEMV": routingsCounts["ecEMV"].toFixed(2),
			"JCB_CD": routingsCounts["JCB_CD"].toFixed(2),
			"maestro": routingsCounts["maestro"].toFixed(2),
			"Mastercard": routingsCounts["Mastercard"].toFixed(2),
			"SepaELV": routingsCounts["SepaELV"].toFixed(2),
			"VISA": routingsCounts["VISA"].toFixed(2),
			"VPAY": routingsCounts["VPAY"].toFixed(2),
			"notdefined": routingsCounts["notdefined"].toFixed(2),
		}
	},
	[FILTERED_TRANSACTIONS](state: GlobalState, getters: IncludeTransactionGetters) {
		return state.transactions
			.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_TRANSACTION_ID(transaction))
			.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_TERMINAL_ID(transaction))
			.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_LOCATION(transaction))
			.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_AMOUNT(transaction))
			.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_TIME(transaction))
			.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_CASHREGISTERCUT_DATE(transaction))
			.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_CARD_TYPE(transaction));

		/** mit dem unteren code funktioniert das filtern schneller, allerdings scheint die vuex mutex nur zu arbeiten, wenn die funktionen direkt aneinander gehängt werden **/
		/*
				let transactions = state.transactions;
				//const filters :any = []; // nein spezielle filterchaining mit every funktioniert auch nicht mit typescript...
				if (state.transactionIdFilter) {
					//filters.push(getters.INCLUDE_TRANSACTION_BY_TRANSACTION_ID);
					transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_TRANSACTION_ID(transaction));
				}
				if (state.transactionTerminalIdFilter) {
					//filters.push(getters.INCLUDE_TRANSACTION_BY_TERMINAL_ID);
					transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_TERMINAL_ID(transaction));
				}
				if (state.selectedBranchlocation) {
					//filters.push(getters.INCLUDE_TRANSACTION_BY_LOCATION);
					transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_LOCATION(transaction));
				}
				if (state.transactionAmountFromFilter || state.transactionAmountToFilter) {
					//filters.push(getters.INCLUDE_TRANSACTION_BY_AMOUNT);
					transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_AMOUNT(transaction));
				}
				if (state.transactionCardTypeFilter && state.transactionCardTypeFilter.length) {
					//filters.push(getters.INCLUDE_TRANSACTION_BY_CARD_TYPE);
					transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_CARD_TYPE(transaction));
				}

				// if(state.transactionTransactionStateFilter) {
				// 	transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_STATE(transaction));
				// }
				// if(state.transactionCurrencyFilter) {
				// 	transactions = transactions.filter((transaction: TransactionSIX) => getters.INCLUDE_TRANSACTION_BY_CURRENCY(transaction));
				// }
				//return transactions;
				//return state.transactions.filter(transaction => filters.every(filter => filter(transaction)));
				/**/
	},

	[INCLUDE_CASHREGISTER_BY_TERMINAL_ID]: (state: GlobalState) => (cashregister: Cashregister) => {
		if (!state.cashregisterTerminalIdFilter) {
			return true;
		}
		return cashregister.terminal_number.includes(state.cashregisterTerminalIdFilter);
	},
	[INCLUDE_CASHREGISTER_BY_AMOUNT]: (state: GlobalState) => (cashregister: Cashregister) => {
		if (!state.cashregisterAmountFromFilter && !state.cashregisterAmountToFilter) {
			return true;
		}
		if (!state.cashregisterAmountToFilter) {
			return cashregister.full_amount >= state.cashregisterAmountFromFilter;
		}
		if (!state.cashregisterAmountFromFilter) {
			return cashregister.full_amount <= state.cashregisterAmountToFilter;
		}
		return cashregister.full_amount >= state.cashregisterAmountFromFilter && cashregister.full_amount <= state.cashregisterAmountToFilter;
	},
	[INCLUDE_CASHREGISTER_BY_CASHREGISTERCUT_DATE]: (state: GlobalState) => (cashregister: Cashregister) => {
		if (!state.cashregistercutDateRange || !state.cashregistercutDateRange[0] || !state.cashregistercutDateRange[1]) {
			return true;
		}
		const filedate = cashregister.filedate.slice(0, 10);
		return filedate >= state.cashregistercutDateRange[0] && filedate <= state.cashregistercutDateRange[1];
	},
	[SUM_AMOUNT_CASHREGISTERS](state: GlobalState, getters: IncludeCashregisterGetters) {
		let cashregisters = state.cashregisters;

		if (state.cashregisterTerminalIdFilter) {
			cashregisters = cashregisters.filter((cashregister: Cashregister) => getters.INCLUDE_CASHREGISTER_BY_TERMINAL_ID(cashregister));
		}
		if (state.cashregisterAmountFromFilter || state.cashregisterAmountToFilter) {
			cashregisters = cashregisters.filter((cashregister: Cashregister) => getters.INCLUDE_CASHREGISTER_BY_AMOUNT(cashregister));
		}
		if (state.cashregistercutDateRange && state.cashregistercutDateRange) {
			cashregisters = cashregisters.filter((cashregister: Cashregister) => getters.INCLUDE_CASHREGISTER_BY_CASHREGISTERCUT_DATE(cashregister));
		}

		let cashregisterSums = {
			full_amount: 0,
			full_transactions: 0,
			alipay_amount: 0,
			alipay_transactions: 0,
			americanexpress_amount: 0,
			americanexpress_transactions: 0,
			cup_cd_amount: 0,
			cup_cd_transactions: 0,
			diners_amount: 0,
			diners_transactions: 0,
			girocard_amount: 0,
			girocard_transactions: 0,
			jcb_cd_amount: 0,
			jcb_cd_transactions: 0,
			maestro_amount: 0,
			maestro_transactions: 0,
			mastercard_amount: 0,
			mastercard_transactions: 0,
			pontos_amount: 0,
			pontos_transactions: 0,
			visa_amount: 0,
			visa_transactions: 0,
			vpay_amount: 0,
			vpay_transactions: 0,
			gutschrift_amount: 0,
			gutschrift_transactions: 0
		}
		cashregisters.forEach((cashregister: Cashregister) => {
			cashregisterSums['full_amount'] += cashregister.full_amount;
			cashregisterSums['full_transactions'] += cashregister.full_transactions;
			cashregisterSums['alipay_amount'] += cashregister.alipay_amount;
			cashregisterSums['alipay_transactions'] += cashregister.alipay_transactions;
			cashregisterSums['americanexpress_amount'] += cashregister.americanexpress_amount;
			cashregisterSums['americanexpress_transactions'] += cashregister.americanexpress_transactions;
			cashregisterSums['cup_cd_amount'] += cashregister.cup_cd_amount;
			cashregisterSums['cup_cd_transactions'] += cashregister.cup_cd_transactions;
			cashregisterSums['diners_amount'] += cashregister.diners_amount;
			cashregisterSums['diners_transactions'] += cashregister.diners_transactions;
			cashregisterSums['girocard_amount'] += cashregister.girocard_amount;
			cashregisterSums['girocard_transactions'] += cashregister.girocard_transactions;
			cashregisterSums['jcb_cd_amount'] += cashregister.jcb_cd_amount;
			cashregisterSums['jcb_cd_transactions'] += cashregister.jcb_cd_transactions;
			cashregisterSums['maestro_amount'] += cashregister.maestro_amount;
			cashregisterSums['maestro_transactions'] += cashregister.maestro_transactions;
			cashregisterSums['mastercard_amount'] += cashregister.mastercard_amount;
			cashregisterSums['mastercard_transactions'] += cashregister.mastercard_transactions;
			cashregisterSums['pontos_amount'] += cashregister.pontos_amount;
			cashregisterSums['pontos_transactions'] += cashregister.pontos_transactions;
			cashregisterSums['visa_amount'] += cashregister.visa_amount;
			cashregisterSums['visa_transactions'] += cashregister.visa_transactions;
			cashregisterSums['vpay_amount'] += cashregister.vpay_amount;
			cashregisterSums['vpay_transactions'] += cashregister.vpay_transactions;
			cashregisterSums['gutschrift_amount'] += cashregister.gutschrift_amount;
			cashregisterSums['gutschrift_transactions'] += cashregister.gutschrift_transactions;
		});

		return cashregisterSums
	},
	[FILTERED_CASHREGISTERS](state: GlobalState, getters: IncludeCashregisterGetters) {
		return state.cashregisters
			.filter((cashregister: Cashregister) => getters.INCLUDE_CASHREGISTER_BY_TERMINAL_ID(cashregister))
			.filter((cashregister: Cashregister) => getters.INCLUDE_CASHREGISTER_BY_AMOUNT(cashregister))
			.filter((cashregister: Cashregister) => getters.INCLUDE_CASHREGISTER_BY_CASHREGISTERCUT_DATE(cashregister))
			;
	},

	[UNIQUE_CARD_TYPES](state: GlobalState) {
		const uniques = new Set<string>();

		state.transactions.forEach((transaction: TransactionSIX) => {
			//let cardType = transaction.meta.match(/(?<=Antwort\s*).*?(?=\s*, EMV)/gs);
			let cardType = transaction.type ? transaction.type: transaction.routing;
			if (cardType) {
				uniques.add(cardType)
			}
		});


		return uniques;
	},

	[UNIQUE_BRANCHLOCATIONS](state: GlobalState) {
		return state.branchlocations.filter(() => true);
	},
	[FILTERED_BRANCHLOCATIONS](state: GlobalState) {
		if (!state.selectedCustomer) {
			return state.branchlocations.filter(() => true);
		}

		return state.branchlocations.filter((branchlocation: Branchlocation) => {
			return state.selectedCustomer && state.selectedCustomer.id === branchlocation.customer_id;
		});
	},

	[UNIQUE_CUSTOMERS](state: GlobalState) {
		return state.customers.filter(() => true);
	},
	[SELECTED_CUSTOMER](state: GlobalState) {
		return state.selectedCustomer;
	},

	[SUM_AMOUNT_INVOICES](state: GlobalState) {
		return state.invoices
			.map((invoice: Invoice) => invoice.amount_gross.toString())
			.reduce((c, v) => {
				return c + parseFloat(v)
			}, 0).toFixed(2)
			;
	},
	[FILTERED_INVOICES](state: GlobalState) {
		return state.invoices.filter(() => true);
	},

	[FILTERED_VGAS](state: GlobalState) {
		return state.vgas.filter(() => true);
	},
	[SEARCH_PARAMS](state: GlobalState) {
		return {
			dateFrom: state.dateRange[0] ? state.dateRange[0] : null,
			dateTo: state.dateRange[1] ? state.dateRange[1] : null,
			searchValue: state.searchValue ? state.searchValue : null,
			customerId: state.selectedCustomer ? state.selectedCustomer.id : null
		};
	}
};

export default getters;
